import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import Layout from "../../components/Layout";
import Grid from "../../components/Grid";
import Typography from "../../components/Typography";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import Button from "../../components/Button";
import HomeBanner from "../../components/HomeBanner";

SwiperCore.use([Autoplay, Navigation]);

export const HomeTemplate = ({ seo, sections, partners, location, banner, cta }) => {
  return (
    <Layout location={location} headerOptions={{ bgColor: "transparent", variant: "fixed-top" }}>
      <Helmet
        title={seo?.title}
        meta={[
          {
            name: "description",
            content: seo?.description,
          },
        ]}
      />
      <HomeBanner
        heading={banner?.heading}
        subtitle={banner?.subtitle}
        buttons={banner?.buttons}
        underline={banner?.underline}
        scrollDownLabel={banner?.scrollDownLabel}
      />
      {(sections ?? []).map((section, idx) => (
        <Grid
          key={section?.title}
          container
          id={`section-${idx + 1}`}
          className="secondary-banner py-56"
          component="section"
        >
          <Grid
            row
            align="center"
            direction={(idx + 1) % 2 === 0 ? "row-reverse" : "row"}
            justify={(idx + 1) % 2 === 0 ? "end" : "start"}
          >
            <Grid column xs={12} md={5} className="mb-24 mb-md-0">
              <img src={section?.logo?.publicURL} height={50} className="mb-16" alt={section?.title} />
              <Typography variant="h2" className="mb-12">
                {section?.title}
              </Typography>
              <Typography variant="paragraph-1" className="mb-24">
                {section?.text}
              </Typography>
              {(section?.buttons ?? []).map((button) => {
                if (button?.external) {
                  return (
                    <a
                      key={button?.to}
                      href={button?.to}
                      title={button?.title}
                      className={clsx(
                        "btn btn-size-3 rounded-pill align-items-end mr-8 btn-has-overlay",
                        `btn-${button.color ?? "primary"}`
                      )}
                      target={button?.newWindow ? "_blank" : null}
                      rel={button?.newWindow ? "noreferrer" : null}
                    >
                      <span
                        className="btn-overlay"
                        style={{ width: button?.label?.length * 10 + 60, height: button?.label?.length * 10 + 60 }}
                      />
                      <span className="btn-label">{button?.label}</span>
                    </a>
                  );
                }

                return (
                  <Link
                    key={button?.to}
                    to={button?.to}
                    title={button?.title}
                    className={clsx(
                      "btn btn-has-overlay btn-size-3 rounded-pill align-items-end mr-8",
                      `btn-${button.color ?? "primary"}`
                    )}
                    target={button?.newWindow ? "_blank" : null}
                    rel={button?.newWindow ? "noreferrer" : null}
                  >
                    <span
                      className="btn-overlay"
                      style={{ width: button?.label?.length * 10 + 60, height: button?.label?.length * 10 + 60 }}
                    />
                    <span className="btn-label">{button?.label}</span>
                  </Link>
                );
              })}
            </Grid>
            <Grid column xs={12} md={6}>
              <div data-aos="fade-up">
                <PreviewCompatibleImage imageInfo={{ image: section?.visual, alt: section?.title }} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container className="mb-n56 mt-120">
        <Grid row justify="center">
          <Grid column xs={12} md={10} xl={8}>
            <Card elevation={1} color="primary" className="text-white">
              <CardBody className="d-flex flex-column align-items-center">
                <Typography variant="h1" component="div" color="white" className="mb-16" align="center">
                  {cta?.title}
                </Typography>
                <Typography variant="subtitle" color="white" className="mb-48" align="center">
                  {cta?.subtitle}
                </Typography>
                {cta?.button?.external ? (
                  <Button
                    color="white"
                    className="text-primary"
                    href={cta?.button?.to}
                    target={cta?.button?.newWindow ? "_blank" : null}
                    rel={cta?.button?.newWindow ? "noreferrer" : null}
                  >
                    {cta?.button?.label}
                  </Button>
                ) : (
                  <Link
                    title={cta?.button?.title}
                    className="btn btn-has-overlay btn-size-3 rounded-pill align-items-end mr-8 btn-white"
                    to={cta?.button?.to}
                    target={cta?.button?.newWindow ? "_blank" : null}
                    rel={cta?.button?.newWindow ? "noreferrer" : null}
                  >
                    <span
                      className="btn-overlay"
                      style={{
                        width: cta?.button?.label?.length * 9 + 50,
                        height: cta?.button?.label?.length * 9 + 50,
                      }}
                    />
                    <span className="btn-label">{cta?.button?.label}</span>
                  </Link>
                )}
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <section className="bg-neutral-20">
        <Grid container className="pt-154 pb-96">
          <Typography variant="h1" component="div" className="mb-40 text-neutral-50">
            {partners?.title}
          </Typography>
          <div className="px-24">
            <Swiper
              navigation
              loop
              slidesPerView={2}
              spaceBetween={30}
              slidesOffsetBefore={24}
              autoplay={{
                delay: 3000,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                },
              }}
              className="brand-swiper"
            >
              {(partners?.logos ?? [])?.map((logo) => (
                <SwiperSlide key={logo?.title}>
                  <PreviewCompatibleImage
                    imageInfo={{ image: logo?.image, alt: logo?.title }}
                    style={{ width: "100%", minWidth: 80, maxWidth: 145 }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Grid>
      </section>
    </Layout>
  );
};

HomeTemplate.propTypes = {
  seo: PropTypes.object,
  banner: PropTypes.object,
  sections: PropTypes.array,
  partners: PropTypes.object,
  cta: PropTypes.object,
  location: PropTypes.object,
};

HomeTemplate.defaultProps = {
  seo: {},
  banner: {},
  sections: [],
  partners: [],
  cta: {},
  location: undefined,
};

const HomePage = ({ data, location }) => {
  const { frontmatter } = data?.markdownRemark || {};
  const { seo, home } = frontmatter ?? {};
  const { sections, partners, banner, cta } = home ?? {};

  return (
    <HomeTemplate seo={seo} sections={sections} banner={banner} partners={partners} location={location} cta={cta} />
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  location: PropTypes.object,
};

HomePage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
    },
  },
  location: undefined,
};

export default HomePage;

export const pageQuery = graphql`
  query homePage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
        }
        home {
          banner {
            heading
            scrollDownLabel
            underline {
              text
              linkText
              popperText
            }
          }
          sections {
            buttons {
              color
              label
              title
              to
              external
              newWindow
            }
            logo {
              publicURL
            }
            text
            title
            visual {
              childImageSharp {
                fluid(maxWidth: 1080, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          cta {
            title
            subtitle
            button {
              label
              to
              external
              newWindow
            }
          }
          partners {
            title
            logos {
              image {
                childImageSharp {
                  fluid(maxWidth: 200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              title
            }
          }
        }
      }
    }
  }
`;
