import React from "react";
import PropTypes from "prop-types";
import { usePopperTooltip } from "react-popper-tooltip";
import Typed from "react-typed";
import { Link as ScrollLink } from "react-scroll";
import { AnimatePresence, motion } from "framer-motion";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import "./style.scss";
import Typography from "../Typography";
import logoVideoMP4 from "../../images/linear-logo-animation.mp4";
import logoVideoWEBM from "../../images/linear-logo-animation.webm";
import Card from "../Card";
import CardBody from "../CardBody";
import Icon from "../Icon";

const HomeBanner = ({ heading, underline, scrollDownLabel }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <div className="home-banner-wrapper">
      <section className="container home-banner position-relative">
        <div className="home-banner-logo-wrapper">
          <video autoPlay muted disableRemotePlayback playsInline>
            <source src={logoVideoWEBM} type="video/webm" />
            <source src={logoVideoMP4} type="video/mp4" />
          </video>
        </div>
        <div className="home-banner-text">
          <div className="position-relative">
            <Typography variant="h1" align="center" className="mb-16">
              <Typed
                strings={[heading]}
                typeSpeed={30}
                showCursor={false}
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              />
            </Typography>
          </div>
          <Typography
            variant="h1"
            align="center"
            component="div"
            aria-hidden="true"
            className="mb-16 opacity-0 pointer-events-none"
          >
            {heading}
          </Typography>
          <Typography variant="subtitle" align="center" className="mb-48">
            {underline?.text?.split(underline?.linkText).reduce((prev, current, i) => {
              if (!i) {
                return [current];
              }
              return prev.concat(
                <span
                  key={underline?.linkText + current}
                  ref={setTriggerRef}
                  className="btn-link p-0 text-primary text-underdash typography subtitle rounded-0"
                >
                  {underline?.linkText}
                </span>,
                current
              );
            }, [])}
          </Typography>
          <AnimatePresence>
            {visible && (
              <div ref={setTooltipRef} {...getTooltipProps({ className: "popper-popover" })}>
                <motion.div
                  initial={{ opacity: 0, y: -5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -5 }}
                  transition={{ duration: 0.2 }}
                  style={{ position: "relative", zIndex: 50 }}
                >
                  <div {...getArrowProps({ className: "popper-arrow" })} />
                  <Card elevation={2}>
                    <CardBody className="p-24">
                      <Typography>{underline?.popperText}</Typography>
                    </CardBody>
                  </Card>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
        </div>
        <div className="d-flex flex-column align-items-center scroll-down-wrapper">
          <ScrollLink to="section-1" smooth offset={-100}>
            <button type="button" className="p-0 btn btn-link">
              <Typography variant="button-3" color="primary">
                {scrollDownLabel}
              </Typography>
            </button>
          </ScrollLink>
          <Icon icon={faArrowDown} className="text-primary" />
        </div>
      </section>
    </div>
  );
};

HomeBanner.propTypes = {
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.array,
  underline: PropTypes.object,
  scrollDownLabel: PropTypes.string,
};

HomeBanner.defaultProps = {
  heading: "The first cross-chain compatible, delta-one asset protocol.",
  subtitle:
    "linear is the first of its kind trading protocol that enables trading of liquid assets seamlessly and effectively.",
  buttons: [],
  underline: {},
  scrollDownLabel: "Scroll down to learn more",
};

export default HomeBanner;
